export default {
  "seo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group "])},
  "seo_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore α Better Future "])},
  "seo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group is a financial services group with financial technology at its core, committed to providing trading and asset management services to our clients."])},
  "seo_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group, forex trading platform online broker online trading forex broker cfd broker"])},
  "common": {
    "language-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language:"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简体中文"])},
    "tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繁體中文"])}
  },
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "group-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Introduction"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestone"])},
    "regulatory-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatory Licenses"])},
    "global-operational-centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Operation Centers"])},
    "business-and-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business & Brand"])},
    "business-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Overview"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brokerage"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wealth Management"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment & Exchange"])},
    "finTech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinTech"])},
    "financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Education"])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Care"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Us"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business & Brand"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership"])},
    "esg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Marketing"])}
  },
  "banner": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official Online Financial Trading Platform Partner"])},
    "headerDSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock the Power of Prestige with the Platinum Dragon Card"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "header-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore α Better Future"])},
    "header-sub-DSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive Rewards, and Seamless International Transaction"])},
    "bottom-text-DSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum Dragon Card is issued and powered by DCS Card Centre Pte. Ltd."])},
    "description-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group is a global financial services group with FinTech as its core. Positioned strategically in global markets, our 10 major business lines, spanning Brokerage, Wealth Management, Property, Payment & Exchange, FinTech, Financial Education, Health Care, Consulting, Cloud, and Digital Marketing, seamlessly converge to provide comprehensive products and services. Through a one-stop approach, Doo Group is dedicated to helping you to achieve ideal financial life and towards a better future together!"])},
    "hongkongEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong FinTech Week<br>Event Highlights"])},
    "hongkongEventDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "anniversary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group's <br class='mobile'>9th Anniversary"])},
    "anniversaryDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Journey to Breakthrough ─ Explore α Better Future"])},
    "ssf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore FinTech Festival<br>Event Highlight"])},
    "ssfDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tokenDubaiHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group to Participate in<br>Token 2049 Dubai"])},
    "tokenDubaiDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit us at <span>Booth M5</span>"])},
    "tokenDubaiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://blog.doo.com/events/enhancing-doo-groups-presence-in-the-new-market-with-token-2049-dubai"])}
  },
  "stats": {
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Clients"])},
    "presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries/Regions Covered by Clients"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutional Partners"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Operation Centers"])}
  },
  "better-future": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 Major Businesses"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group our 10 major business lines deliver a wide range of financial services, providing clients with comprehensive and innovative solutions to unlock boundless possibilities."])},
    "title-brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brokerage"])},
    "doo-clearing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing aims to provide institutional clients with liquidity access, order execution, settlement, custody, and clearing services for financial products such as securities, futures, foreign exchange, and CFDs."])},
    "doo-finance-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial aims to provide professional investors with trading brokerage services for global securities, futures, CFDs and other financial products to achieve one-click allocation of global financial assets."])},
    "doo-prime-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime aims to provide professional investors with contract for CFD trading and brokerage services for global financial products, striving to become your dedicated and exclusive broker."])},
    "title-wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wealth Management"])},
    "doo-wealth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth aims to provide investors with one-stop wealth management services covering insurance, securities, fund, real estate, identity planning, trust and family office, striving to create safer and easier global investment experience with a lower threshold."])},
    "title-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment & Exchange"])},
    "doo-payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By opening a global account through Doo Payment, you can utilize advanced financial technology to achieve global capital flows and efficiently seize business opportunities in the digital era."])},
    "lesspay-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By opening a global account through LessPay, you can utilize advanced financial technology to achieve global capital flows and efficiently seize business opportunities in the digital era."])},
    "title-finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinTech"])},
    "doo-finpoints-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By deeply integrating technology and innovation, FinPoints creates extensive, comprehensive, and advanced solutions, utilizing the power of technology to revolutionize the global financial industry."])},
    "title-financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Education"])},
    "financial-education-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Academy aims to provide accurate financial knowledge to investment enthusiasts, helping them to achieve wealth goals with a broader personal investment horizon."])},
    "title-health-care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Care"])},
    "health-care-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Health aims to provide a safer and more convenient health management experience to clients with an all-round digital health service platform."])},
    "title-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "doo-property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Property is committed to enhancing clients' property journey by providing a comprehensive suite of services designed to fulfill clients' needs with precision and the utmost care."])},
    "title-consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
    "doo-consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting offers seamless business support, along with diverse consulting services, ensures smooth operations and exceptional client experiences."])},
    "title-cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "doo-cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Cloud introduces inclusive solution designed to cater to global market's needs, ensuring access to top-tier products and services, supported by advanced technology integration capabilities."])},
    "title-digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Marketing"])},
    "doo-digital-marketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Digital Plus empowers business to stay ahead in the fast-evolving digital landscape through cutting-edge strategies and innovative approaches."])}
  },
  "license": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International License"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under the strict supervision of top international institutions, the entities under Doo Group hold more than 20 regulatory licenses, offering a secure and dependable range of financial services to clients worldwide. Therefore, you can confidently choose us as your financial partner as the financial security and trading transparency of each client are safeguarded by professional third-party organizations."])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
  },
  "home-global-presence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Presence"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With business operations spanning across the globe, Doo Group has established 20+ operation centers in major cities worldwide, assisting you in trascending investment boundaries and seizing boundless opportunities."])}
  },
  "global-presence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting the World with Global Expansion"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group embarks on a global expansion with a total of 20+ global operation centers and over 1000 employees, dedicated to providing high-quality localized services."])}
  },
  "global-partnership": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Partnerships"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have established long-term collaborative relationships with high-quality partners across various fields worldwide, aiming to build an ideal financial ecosystem and achieve mutual growth and success."])}
  },
  "future-opportunity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Look Forward to Your Contact and Creating Boundless Opportunities Together"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sincerely welcome all types of inquiries, whether it's services consultation or business partnerships. Feel free to get in touch with us!"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "latest-updates": {
    "latest-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
  },
  "esg": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG Initiatives"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guided by the principles of Environmental, Social, and Governance (ESG), we are committed to sustainable development. As the group progresses steadily, we spare no effort in promoting societal advancement and working towards a better future."])}
  },
  "about": {
    "group-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Introduction"])},
    "group-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Financial Services, Your One-Stop Journey to a Better Future"])},
    "betterFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore α Better Future"])},
    "betterFutureDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group, established in 2014 and headquartered in Singapore, is an international financial services group with FinTech as its core. Operating through 10 major business lines, including Brokerage, Wealth Management, Property, Payment & Exchange, FinTech, Financial Education, Health Care, Consulting, Cloud, and Digital Marketing, Doo Group continually strengthens our interconnected business ecosystem. We are dedicated to providing comprehensive financial services and innovative solutions to clients worldwide. Together, we embark on a journey to Explore α Better Future."])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
    "missionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To provide clients with secure, professional and convenient one-stop high-quality financial services and innovative solutions."])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
    "visionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To become a global leading financial services group with financial technology as its core."])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
    "values-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients Come First"])},
    "values-1-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our dedication lies in delivering professional services and ensuring satisfying client experiences. We strive to consistently generate value for our clients."])},
    "values-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrity, Reliability, and Fairness"])},
    "values-2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a financial company, our core responsibility lies in safeguarding the security of client assets and the fair treatment of their rights and interests."])},
    "values-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Social Responsibility"])},
    "values-3-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since our establishment, we have not regarded profit as the sole objective of our company. We are committed to taking on our rightful corporate social responsibilities and contributing to the sustained development of individuals, industries, and society as a whole."])},
    "businessAndBrands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Business and Brands"])},
    "businessAndBrandsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our global team has continuously attracted exceptional talent around the world. With a number of sub-brands, we offer services and solutions in different business categories. Check out our brands and services today!"])},
    "financialTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinTech"])},
    "milestone-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestone"])},
    "milestone-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forging a glorious journey with continuous innovation and transformation."])},
    "developmentJourney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Development Journey"])},
    "developmentJourneyDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since our establishment in 2014, Doo Group has developed ten major business lines to expand into global markets. With the dedication to build a diverse financial ecosystem, we continuously providing comprehensive financial services to over 200,000 clients worldwide."])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Development"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Presence"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Development"])},
    "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
    "m-2014-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group was established as a financial technology group."])},
    "m-2014-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established core R&D and technical support team."])},
    "m-2014-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Hong Kong, China office"])},
    "m-2015-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced internationally recognised professional trading software MT4 to provide popular currency pair products."])},
    "m-2016-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Seoul, Korea office"])},
    "m-2016-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expanded precious metals, energy, and global mainstream stock trading indexes."])},
    "m-2016-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Released BrokerForce version 3.0 to comprehensively improve system stability and user experience."])},
    "m-2017-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced Doo Clearing to provide brokers with high-quality liquidity access and clearing services."])},
    "m-2017-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the London, U.K. office"])},
    "m-2017-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained the FSC license from Vanuatu Financial Services Commission (VU FSC)."])},
    "m-2017-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing a new generation of trading software MT5 to support more powerful features."])},
    "m-2017-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launched Bridge Cloud to help brokers with top-level liquidity clearing bridge services."])},
    "m-2018-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated retail customer business, and introduced a new retail broker brand, Doo Prime."])},
    "m-2018-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Taipei, China office"])},
    "m-2018-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Kuala Lumpur, Malaysia office"])},
    "m-2018-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained the MSO license from Hong Kong Customs and Excise Department"])},
    "m-2018-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo MT5 trading platform is officially launched, and it is more powerful and easier to use. The multi-asset platform innovates the user trading experience."])},
    "m-2018-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BrokerForce version 4.0 upgrade unveiled."])},
    "m-2019-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutional clients reached 400+."])},
    "m-2019-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Singapore office"])},
    "m-2019-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Mauritius office"])},
    "m-2019-g3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Seychelles office"])},
    "m-2019-g4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Vanuatu office"])},
    "m-2019-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from Financial Conduct Authority (UK FCA) in the United Kingdom."])},
    "m-2019-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained the TCSP license from Hong Kong Companies Registry"])},
    "m-2019-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained the FINTRAC license from Financial Transactions and Reports Analysis Centre of Canada (CA FINTRAC)"])},
    "m-2019-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to explore various trading options, as well as access richer US stock and futures trading products."])},
    "m-2019-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperated with a number of fintech companies to launch In Trade, a trading terminal, and Outrade, a community trading platform"])},
    "m-2019-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to TradingView, a leading cloud and web-based trading platform, to help clients exchange massive chart indicators and trading ideas."])},
    "m-2019-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established a strategic partnership with Myfxbook to strongly promote the AutoTrade mirror trading system."])},
    "m-2020-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquired Peter Elish Investments Securities, a U.S. broker with a 30-year history"])},
    "m-2020-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a FinTech provider, FinPoints."])},
    "m-2020-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnered with Trading Central to create more diverse and comprehensive professional technical analysis tools."])},
    "m-2020-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Dallas, U.S. office"])},
    "m-2020-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Ho Chi Minh City, Vietnam office"])},
    "m-2020-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained the licenses from the United States Securities and Exchange Commission (US SEC) and the Financial Industry Regulatory Authority (US FINRA)."])},
    "m-2020-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from Mauritius Financial Services Commission (MU FSC)."])},
    "m-2020-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased Professional Indemnity Insurance (PII) to protect the safety of customers’ assets as well as their rights and interests in all aspects."])},
    "m-2020-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added 300+ star US and Hong Kong stocks and futures products."])},
    "m-2020-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered FOLLOWME, the world's leading forex trading community, to cooperate to enhance clients' social trading experience."])},
    "m-2020-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined forces with MTE Media, a one-stop financial education platform, to provide massive investor education resources."])},
    "m-2020-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperated to build Price Cloud, a quotation distribution system, to provide fast and stable multi-market quotation access and integration services."])},
    "m-2020-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered a dedicated Virtual Private Server (VPS) to enable a more secure and uninterrupted trading environment for customers."])},
    "m-2021-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a brokerage brand, Doo Financial.​"])},
    "m-2021-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global clients reached 40,000+​"])},
    "m-2021-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutional Partners reached 250+​"])},
    "m-2021-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial has a full disclosure brokerage relationship with Interactive Brokers.​"])},
    "m-2021-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership with oneZero."])},
    "m-2021-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic partnership with Abans.​"])},
    "m-2021-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed MOU with Bison Bank.​"])},
    "m-2021-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial acquired Australian brokerage Kinsmen Securities Limited.​"])},
    "m-2021-b9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formed \"Doo Bursary\" on a venture with King's College London, UK.​"])},
    "m-2021-b10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime launched \"Charity Stars\" program.​"])},
    "m-2021-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Bangkok, Thailand office"])},
    "m-2021-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Dubai, UAE office"])},
    "m-2021-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained Brokerage license from Australian Securities and Investments Commission (ASIC)."])},
    "m-2021-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from Financial Services Authority Seychelles (SC FSA)."])},
    "m-2021-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperate to promote the Clearing Cloud large-scale, multilateral clearing system and assist brokers of different sizes in the industry in connecting with liquidity providers."])},
    "m-2021-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered traders with more than 10,000 trading products."])},
    "m-2021-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch of Doo Prime TradingView trading interface. "])},
    "m-2021-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch of Doo Prime InTrade version 2.1.0 "])},
    "m-2021-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Launched Version 3.0 Official Website, Media Center, Help Center "])},
    "m-2021-p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Launched Points Mall Thailand"])},
    "m-2022-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a wealth management brand, Doo Wealth."])},
    "m-2022-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global clients reached 60,000+."])},
    "m-2022-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial acquired wealth management company, namely Doo Wealth Management HK Limited."])},
    "m-2022-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Became an official partner of Adelaide United Football Club"])},
    "m-2022-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined hand with UNICEF Hong Kong to support the rights of children"])},
    "m-2022-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened up markets in Japan and Indonesia."])},
    "m-2022-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from the Hong Kong Insurance Authority (HK Insurance Broker). "])},
    "m-2022-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained Type 4 and Type 9 licenses from the Hong Kong Securities and Futures Commission (HK SFC)."])},
    "m-2022-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from the Hong Kong Estate Agents Authority (HK EAA)."])},
    "m-2022-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from the Australian Transaction Reports and Analysis Centre (AUSTRAC)"])},
    "m-2022-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced Doo Prime InTrade version 2.6.4. "])},
    "m-2022-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launched the MT5 trading platform on Doo Prime. "])},
    "m-2022-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officially launched Doo Prime CopyTrading. "])},
    "m-2023-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global clients reached 200,000+"])},
    "m-2023-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a payment exchange brand, Doo Payment."])},
    "m-2023-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a financial education brand, Doo Academy."])},
    "m-2023-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a health care brand, Doo Health."])},
    "m-2023-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Became an official global partner of Manchester United Football Club"])},
    "m-2023-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Became a proud sponsor of the Australia China Friendship Society Western Australia (ACFSWA)."])},
    "m-2023-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Became a gold sponsor of Spastic Children's Association of Selangor & Federal Territory"])},
    "m-2023-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Payment acquired remittance and digital currency exchange provider, namely Doo Exchange AU Pty Ltd."])},
    "m-2023-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established the Sydney, Australia office and customer center"])},
    "m-2023-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered as a Hong Kong Trust Company by the Hong Kong Companies Registry"])},
    "m-2023-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained license from the Malaysia Labuan Financial Services Authority (MY Labuan FSA). "])},
    "m-2023-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained license from the Dubai Real Estate Regulatory Agency (AE RERA)"])},
    "m-2023-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained license from the United States Financial Crimes Enforcement Network (US FinCEN)"])},
    "m-2024-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a property brand, Doo Property"])},
    "m-2024-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a consulting brand, Doo Consulting"])},
    "m-2024-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a cloud brand, Doo Cloud"])},
    "m-2024-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a digital marketing brand, Doo Digital Plus"])},
    "m-2024-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries/Regions where clients come from reached 150+"])},
    "m-2024-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained Type 1 license from the Hong Kong Securities and Futures Commission (HK SFC)"])},
    "m-2024-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from the Monetary Authority of Singapore (SG MAS)"])},
    "m-2024-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from the Dubai's Department of Economy and Tourism (AE PSP)"])},
    "m-2024-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained a license from the Central Bank of the UAE (CBUAE)"])},
    "m-2024-s5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained Payment Exchange license from the Australian Securities & Investments Commission (ASIC)"])},
    "m-2024-s6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained  a license from the Hong Kong Customs and Excise Department DPMS Supervision Bureau (HK DPMS)"])},
    "license-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatory Licenses"])},
    "license-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adhering to \"Clients Come First\", Doo Group consistently strengthens our financial ecosystem under top global financial oversight for the safety of client assets."])},
    "strictSupervision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Guarantees with Strict Supervision"])},
    "strictSupervisionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As an internationally leading financial services group, Doo Group currently holds 20+ international regulatory licenses, continuously enhancing the global financial regulatory framework."])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brokerage"])},
    "wealthManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wealth Management"])},
    "paymentExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment & Exchange"])},
    "US_SEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US SEC<br>United States Securities and Exchange Commission"])},
    "US_SEC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: SEC 8-41551"])},
    "US_FINRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US FINRA​<br>United States Financial Industry Regulatory Authority"])},
    "US_FINRA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: CRD 24409"])},
    "UK_FCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK FCA​<br>United Kingdom Financial Conduct Authority"])},
    "UK_FCA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 833414"])},
    "AU_ASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIC​<br>Australian Securities & Investments Commission (Brokerage)​"])},
    "AU_ASIC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 222650"])},
    "AU_AUSTRAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUSTRAC​<br>Australian Transaction Reports and Analysis Centre​​"])},
    "AU_AUSTRAC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independent Remittance: IND100812107-001​<br>Digital Currency Exchange: DCE100812107-001"])},
    "HK_SFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK SFC​<br>Hong Kong Securities and Futures Commission (Type 1, 4, 9 licenses)"])},
    "HK_SFC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Entity No: BSM562​"])},
    "HK_Insurance_Broker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK Insurance Broker​<br>Hong Kong Insurance Authority"])},
    "HK_Insurance_Broker_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["​License No: FB1823​"])},
    "HK_Trust_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK Trust Company​<br>Hong Kong Companies Registry"])},
    "HK_Trust_Company_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company No: 2601567​"])},
    "HK_MSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK MSO​<br>Hong Kong Customs and Excise Department"])},
    "HK_MSO_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 18-06-02444​"])},
    "HK_EAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK EAA​<br>Hong Kong Estate Agents Authority"])},
    "HK_EAA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: C-094694​"])},
    "SC_FSA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SC FSA​<br>Seychelles Financial Services Authority"])},
    "SC_FSA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: SD090"])},
    "MY_Labuan_FSA-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY Labuan FSA​<br>Malaysia Labuan Financial Services Authority"])},
    "MY_Labuan_FSA-1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities License No: SL/23/0022"])},
    "MY_Labuan_FSA-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY Labuan FSA​<br>Malaysia Labuan Financial Services Authority​"])},
    "MY_Labuan_FSA-2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Broking Business License No: MB/23/0108"])},
    "MU_FSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MU FSC​<br>Mauritius Financial Services Commission​"])},
    "MU_FSC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: C119023907"])},
    "VU_FSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VU FSC​<br>Vanuatu Financial Services Commission​"])},
    "VU_FSC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 700238​"])},
    "US_FINCEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US FinCEN​<br>United States Financial Crimes Enforcement Network"])},
    "US_FINCEN_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSB Registration Number: 31000255627030"])},
    "CA_FINTRAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA FINTRAC​<br>Financial Transactions and Reports Analysis Centre of Canada"])},
    "CA_FINTRAC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSB Registration Number: M19501973"])},
    "ASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIC​<br>Australian Securities & Investments Commission (Payment & Exchange)"])},
    "ASIC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 444062"])},
    "SG_MAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG MAS<br>Monetary Authority of Singapore"])},
    "SG_MAS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: CMS101428"])},
    "HK_DPMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK DPMS<br>Hong Kong Customs and Excise Department DPMS Supervision Bureau"])},
    "HK_DPMS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: A-B-24-04-06541"])},
    "HK_TCSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK TCSP<br>Hong Kong Companies Registry"])},
    "HK_TCSP_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: TC006709"])},
    "AE_RERA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE RERA<br>Dubai Real Estate Regulatory Agency"])},
    "AE_RERA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 1172628"])},
    "CBUAE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CBUAE<br>Central Bank of the UAE"])},
    "CBUAE_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No:226"])},
    "AE_PSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE PSP<br>Dubai's Department of Economy and Tourism"])},
    "AE_PSP_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License No: 1312503"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Operation Centers"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group regards serving global investors as our mission, continually expanding our global business footprint to meet the needs of clients worldwide."])},
    "global-office-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Operation Centers"])},
    "global-office-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group regards serving global investors as our mission, continually expanding our global business footprint to meet the needs of clients worldwide."])},
    "global-operations-centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a Tour of Our Global Operation Centers"])},
    "hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["London"])},
    "kl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuala Lumpur"])},
    "sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "jlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubai (JLT)"])},
    "ems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubai (Emaar Square)"])}
  },
  "aufc": {
    "seo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelaide United ", "|", " Partnership "])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paving A Future Together. Doo Group and Adelaide United strive toward greater heights"])},
      "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelaide United, Doo Group, Adelaide Utd"])}
    },
    "aufc-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT"])},
    "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIGHLIGHTS"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOALS"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paving A Future Together"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Adelaide United strive toward greater heights"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIGHLIGHTS"])},
    "highlights-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore both giants’ rich history and accomplishments"])},
    "DooGroup-2023-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global clients reached 200,000+"])},
    "DooGroup-2023-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a payment exchange brand, Doo Payment."])},
    "DooGroup-2023-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a financial education brand, Doo Academy."])},
    "DooGroup-2023-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a health care brand, Doo Health. "])},
    "DooGroup-2023-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established a global partnership with Manchester United."])},
    "DooGroup-2023-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Became a proud sponsor of the Australia China Friendship Society Western Australia (ACFSWA) "])},
    "DooGroup-2023-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgraded as the Gold global Partner of AUFC."])},
    "AdelaideUnited-2023-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelaide United celebrated their 20th Anniversary "])},
    "AdelaideUnited-2023-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgraded as the Gold global Partner of Doo Group."])},
    "DooGroup-2022-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a wealth management brand, Doo Wealth."])},
    "DooGroup-2022-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global clients reached 60,000+."])},
    "DooGroup-2022-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established a global partnership with Adelaide United (AUFC). "])},
    "AdelaideUnited-2022-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established a global partnership with Doo Group."])},
    "DooGroup-2021-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a brokerage brand, Doo Financial."])},
    "DooGroup-2021-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional clients reached 40,000+ "])},
    "DooGroup-2021-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established partnerships with Interactive Brokers and oneZero."])},
    "DooGroup-2021-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established strategic partnerships with Abans."])},
    "DooGroup-2021-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed MOU with Bison Bank."])},
    "DooGroup-2021-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formed “Doo Bursary” on a venture with King’s College London, United Kingdom."])},
    "DooGroup-2021-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established offices in Bangkok, Thailand, and Dubai, United Arab Emirates."])},
    "DooGroup-2020-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a FinTech provider, FinPoints."])},
    "DooGroup-2020-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established offices in Dallas, United States, and Ho Chin Minh City, Vietnam."])},
    "DooGroup-2018-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a new retail brokerage brand, Doo Prime."])},
    "DooGroup-2018-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established an office in Kuala Lumpur, Malaysia."])},
    "DooGroup-2017-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduced a brokerage brand, Doo Clearing."])},
    "DooGroup-2017-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established an office in London, United Kingdom."])},
    "AdelaideUnited-2016-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Won the inaugural A-league Championship against the Queensland Roar in the grand final."])},
    "DooGroup-2014-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formed as a global FinTech group. "])},
    "DooGroup-2014-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established first office in Hong Kong, China. "])},
    "AdelaideUnited-2013-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secured first-ever Johnny Warren Medal through Marcos Flores, named the league's best player."])},
    "AdelaideUnited-2013-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Striker Sergio van Dijk also claimed the Hyundai A-League Golden Boot, finishing as the top goal-scorer with 16 goals during the regular season. "])},
    "AdelaideUnited-2008-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historic 1-0 victory over Japanese giants Kashima Antlers in the AFC Champions League final, making them the first Australian team to reach the final and win a major Asian club competition."])},
    "AdelaideUnited-2003-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded in September 2003. "])},
    "AdelaideUnited-2003-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined the National Soccer League (NSL) ahead of the 2003/04 season after the withdrawals of West Adelaide and Adelaide City. "])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover &#064;doo group"])},
    "discover-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay connected with us! Follow us on social media for updates, insights and a glimpse into our world"])},
    "goals-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and AUFC are moving towards a better future together, striving for success in their joint endeavors."])},
    "ambition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambition"])},
    "ambition-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fueled by unwavering commitment to passion and excellence, Doo Group and AUFC constantly push boundaries to achieve new heights.  "])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty"])},
    "loyalty-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group’s pledges loyalty through professional services and consistent value addition. Simultaneously, AUFC stands as a custodian for South Australians, fostering passion, pride, and purpose through football."])},
    "coin-toss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin Toss session at the Gold Global Partnership event of Doo Group and AUFC."])},
    "cooper-stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coopers Stadium, the proud home ground of AUFC."])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group's LED displayed at all home matches in Cooper Stadium."])},
    "dg-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group logo on the men's home and away shorts throughout the Isuzu UTE A-League."])},
    "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The strong connections between AUFC and the local community."])},
    "transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency"])},
    "transparency-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group always champions transparency to safeguard client rights and interests. Just as AUFC echoes its commitment with open communication, disclosure, and accountability in all operations. "])},
    "community-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
    "community-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our 10 major business lines cover a wide range of financial services, providing clients with a full range of financial services and innovative solutions to explore boundless possibilities."])},
    "doo-group-aus-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Sydney Headquarter"])},
    "doo-group-aus-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite 1302, Level 13, Tower 1, 475 Victoria Avenue, Chatswood NSW 2067."])},
    "doo-group-aus-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact No.   02 8801 3188"])},
    "doo-group-chatswood-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Chatswood Branch"])},
    "doo-group-chatswood-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop 3, Ground Floor, 408-414 Victoria Avenue, Chatswood NSW 2067"])},
    "doo-group-chatswood-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact No.   02 8801 3186"])},
    "doo-group-chinatown-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Chinatown Branch"])},
    "doo-group-chinatown-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop D1, Sussex Centre, 56 Dixon Street, Haymarket NSW 2000"])},
    "doo-group-chinatown-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact No.   02 8801 3183"])},
    "doo-group-customer-center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Customer Center"])},
    "doo-group-service-center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Service Center"])},
    "opening-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Soon in following locations"])},
    "partnership-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay tuned for more exciting updates on our partnership with Adelaide United."])}
  },
  "business-overview": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Overview"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group encompasses diversified business, leading clients towards a better future!"])},
    "six-major-business-lines-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building Interconnected Business Ecosystem<br>with 10 Major Business Lines"])},
    "six-major-business-lines-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group reimagines the possibilities of business ecosystems with the concept of “Limitless” – to create a business ecosystem beyond boundaries. Backed by advanced technology, Doo Group is committed to forging a future of limitless potential through continuous innovation and transformation."])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brokerage"])},
    "brokerage-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covering securities, futures, forex and CFDs, we provide institutional clients and professional investors with a variety of brokerage services to achieve one-click allocation of global financial investments.​​"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wealth Management"])},
    "wealth-management-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A professional financial advisory services who keep abreast of your financial goals and risk preferences, and carefully scout around for one-stop investment and financial solutions, allowing you to manage effectively, add value to and inherit wealth. ​​​"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment & Exchange"])},
    "payment-exchange-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embark on a journey into the future of payments, where tailored solutions and global payment needs are seamlessly integrated, not an afterthought.​​"])},
    "fintech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinTech"])},
    "fintech-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A solid support to your business with our diversified FinTech solutions, including clearing system, market data and more.​​"])},
    "financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Education"])},
    "financial-education-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our thriving financial education platform, you are equipped to make informed investment decisions and manage your wealth towards a sustainable future.​​"])},
    "health-care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Care"])},
    "health-care-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The greatest wealth is health. Discover a healthier and more beautiful version of yourself through our interconnected health care ecosystem that focuses on precision medicine.​​"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevate your property experience with our inclusive services. From expert advice to efficient management, and successful brokerage, we ensure your property needs are met with precision."])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
    "consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailored to meet your needs, our seamless business support, along with diverse consulting services, ensures smooth operations and exceptional client experiences."])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comprehensive solution designed to cater to the global market’s needs, ensuring access to top-tier products and solutions supported by advanced technology integration capabilities."])},
    "digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Marketing"])},
    "digital-marketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner with us to achieve unparalleled digital results. Our innovative strategies drive us to stay ahead of the market, ensuring your business remains at the forefront of the digital landscape."])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel Free to Contact Us"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our diversified business and explore more collaboration opportunities​"])},
    "contact-us-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us Now"])}
  },
  "brokerage": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brokerage"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-click Allocation of Global Financial Investments"])},
    "doo-clearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing"])},
    "doo-clearing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A global FinTech based provider providing instituitonal clients with liquidity access, order execution and clearing services for Securities, Futures, Forex, CFDs and more."])},
    "doo-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial"])},
    "doo-financial-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A one-stop investment channel offering diverse trading opportunities in Securities, Futures, Funds, and Custodian Services, enabling professional investors, who are subject to higher regulatory standards, to invest globally through a single account."])},
    "doo-prime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime"])},
    "doo-prime-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An international preeminent online broker empowering professional investors with a diverse range of global CFD trading products in Securities, Futures, Forex, Commodities, Precious Metals, and Stock Indices. "])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Brokerage business and explore more collaboration opportunities"])}
  },
  "wealth-management": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wealth Management"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-stop Journey to Your Ideal Financial Future"])},
    "doo-wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth"])},
    "doo-wealth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comprehensive global wealth management service system designed to meet clients' needs. With extensive experience in insurance, securities, fund, real estate, identity planning, trust and family office, Doo Wealth's professional advisor team provides personalized solutions according to clients' risk tolerance and objectives."])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Wealth Management business and explore more collaboration opportunities"])}
  },
  "payment-exchange": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment & Exchange"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Capital Flows at Your Convenience"])},
    "doo-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Payment"])},
    "doo-payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A payment and foreign currency exchange business brand supporting a wide range of currencies, breaking down geographical boundaries. With intelligent financial facilities, Doo Payment provides clients with a more convenient and cost-efficient way to make international transfer."])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Payment & Exchange business and explore more collaboration opportunities"])}
  },
  "finTech": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinTech"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pioneering Technology for a New Era in Finance"])},
    "finpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinPoints"])},
    "finpoints-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A global leading FinTech provider harnessing the synergy of technology and innovation to transform the support your business with comprehensive solutions and products, including Fin Cloud, Market Data Cloud, Clearing Cloud, Market Data and InTrade. "])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our FinTech business and explore more collaboration opportunities"])}
  },
  "financial-education": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Education"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieving Value-Driven Life Through Investment"])},
    "doo-academy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Academy"])},
    "doo-academy-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An extensive financial education platform providing individuals with professional financial information through a series of services and products, such as online/offline course, live stream and exclusive study group. "])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Financial Education business and explore more collaboration opportunities"])}
  },
  "healthcare": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Care"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empowering Vibrant Life Through Health Technology"])},
    "doo-health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Health"])},
    "doo-health-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dedicated health care brand where precision medicine meets personalized well-being. Doo Health stands at the forefront of transforming health experience, specializing in body check-up, vaccinations, anti-ageing and stem cell​ solutions."])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Health Care business and explore more collaboration opportunities"])}
  },
  "contact_us": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to leave your contact information, and a dedicated advisor from Doo Group will reach out to you within 1 working day."])},
    "time_zone_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-Class International Customer Service"])},
    "time_zone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200+ customer service and 300+ technicians from different countries and regions around the world, providing 24/7 multilingual service support."])},
    "new_york": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New York"])},
    "london": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["London"])},
    "south_africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "dubai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubai"])},
    "hong_kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave Your Contact Information"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number (with country code)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization/Company Name"])},
    "service_consultation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Consultation"])},
    "business_cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Cooperation"])},
    "media_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Interviews"])},
    "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query Type"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the brands you wish to inquire about (multiple choices available)"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), " is required"])},
    "number_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), " must be number"])},
    "email_notValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
    "message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message is required"])},
    "hCaptcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete hCaptcha"])},
    "submit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message. It has been sent."])},
    "submit_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message failed to send."])},
    "checkbox_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one."])}
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "doogroup.com"])},
    "hp-sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "hp-sg-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1736"])},
    "hp-hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "hp-hk-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 2632 9557"])},
    "follow-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "group-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Introduction"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestone"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatory Licenses"])},
    "global-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Office"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Us"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business & Brand"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Overview"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brokerage"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wealth Management"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment & Exchange"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Technology"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerships"])},
    "our-partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Partners"])},
    "Mu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manchester United"])},
    "Au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelaide United"])},
    "ESG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Center"])},
    "compliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance and Risk Disclosure"])},
    "brand-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand and Entity Notice"])},
    "client-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Notice"])},
    "entities-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Entities Notice"])},
    "body": {
      "compliance-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Technology Singapore Pte. Ltd., the technology arm of Doo Group."])},
      "compliance-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Technology Singapore Pte. Ltd. is a company with the registered office address at 1 Temasek Avenue, #24 – 01A, Millenia Tower, Singapore 039192 and the company registration number is 202104581D."])},
      "compliance-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website can be accessed globally and is not specific to any entity. This is mainly for the convenience of centralized display and comparison of information. Your actual rights and obligations will be determined based on the entity and jurisdiction that you choose to be regulated."])},
      "compliance-description-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be of at least 18 years old before you can access our website products and services. By accessing our website, you confirm you are an individual of full age and capacity."])},
      "compliance-description-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There may be local laws and regulations which prohibit or limit your rights to access, download, distribute, disseminate, share or otherwise use any or all of the documents and information published on this website."])},
      "compliance-description-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities, Futures, CFDs and other financial products involves high risks due to the fluctuation in the value and prices of the underlying financial instruments. Due to the adverse and unpredictable market movements, large losses exceeding your initial investment could incur within a short period of time."])},
      "compliance-description-seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past performance of an investment is not an indication of its performance in the future. Please read the compliance documents available on our website."])},
      "compliance-description-eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure you fully understand the risks of trading with the respective financial instrument before engaging in any transactions with us. You should seek independent professional advice if you do not understand the risks explained herein."])},
      "brand-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing, Doo Financial, Doo Wealth, Doo Payment and Exchange, LessPay, FinPoints, Doo Academy, Doo Health, Peter Elish and Doo Consulting are trading names, trademarks and intellectual properties of Doo Group."])},
      "brand-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a variety of regulated and licensed entities in different jurisdictions to provide relevant financial services. You are able to choose to establish business relationships with any of our entities. Please bear in mind that the entity chosen meant that your trading accounts, trading activities and funds shall be governed and regulated by its regulatory authority only."])},
      "brand-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not offer our services to individuals or companies of certain jurisdictions, including but not limited to Afghanistan, Azerbaijan, Bosnia and Herzegovina, Burma, Canada, Congo, Cuba, Ethiopia, Guyana, Haiti, Hong Kong, Iran, Iraq, Israel, Japan, Lebanon, Malaysia, Mali, North Korea, Singapore, Sri Lanka, Somalia, Sudan, Trinidad and Tobago, Tunisia, Venezuela, United Arab Emirates and United States of America. The information and services on our website are not applicable and shall not be provided to country or jurisdiction where such distribution of information and services are contrary to the respective local statutes and regulations. Visitors from the above regions should confirm whether your decision to invest in our services is in accordance with the statutes and regulations in your country or jurisdiction before you use our services. We reserve our rights to vary, amend or discontinue our products and services at any time."])},
      "client-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any trading symbols displayed herein are for illustrative purposes only and shall not constitute any advice or recommendations by us. Any comments, statements, data, information, material or third party material (“Material”) provided on this website are for reference purposes only. The Material is used solely for the purposes of marketing communication and does not contain, and should not be construed as investment advice and/or an investment recommendation for any transactions. While we took all reasonable efforts to ensure the accuracy and completeness of the information, we make no representations and warranties to the Material and shall not be liable for any loss, including but not limited to loss of profit, direct or indirect loss or damages for any inaccuracies and incompleteness from the information provided. You shall only use the Material for personal use and shall not reproduce, copy, redistribute and/or license the Material without our consent."])},
      "client-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies on our websites to customize the information and experience displayed on our website according to your preferences. By accessing this website, you acknowledge that you have read and agree to the details above and agree to our use of cookies"])},
      "client-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We strictly comply with all applicable laws and regulations in jurisdictions. It is your responsibility to determine and ensure that your investment meets your requirements. You undertake to bear all the consequences of your investment and trading activities."])},
      "client-description-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Group. © 2023 Doo Group. All Rights Reserved."])},
      "ellish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "ellish-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at 5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States."])},
      "ellish-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc is a broker-dealer, authorized and regulated by the United States Securities and Exchange Commission (US SEC) and Financial Industry Regulatory Authority (US FINRA) with the regulation number SEC: 8-41551 and CRD: 24409 respectively."])},
      "ellish-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities is an authorised trading name by Elish & Elish Inc."])},
      "clearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "clearing-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF, and the company registration number is 10684079."])},
      "clearing-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited is a futures and forex broker and liquidity provider, authorized and regulated by the United Kingdom Financial Conduct Authority (UK FCA), and the regulatory number is 833414."])},
      "financialAus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "financialAus-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at ‘Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia and the Australian Company Number is 100 139 820."])},
      "financialAus-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited is an Australian financial services licensee, authorized and regulated by the Australian Securities & Investments Commission (ASIC), and the regulatory number is 222650."])},
      "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "exchange-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at ‘Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia, and the Australian Company Number is 661 717 237."])},
      "exchange-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd is a Currency Exchange and Remittance service provider, authorized and regulated by the Australian Transaction Reports and Analysis Centre (AUSTRAC), and the authorizations number are IND100812107-001 and DCE100812107-001 respectively."])},
      "financialHk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial HK Limited"])},
      "financialHk-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, and the company registration number is 2099737."])},
      "financialHk-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial HK Limited is licensed for Type 4 (advising on securities) and Type 9 (asset management) activities, regulated by the Securities and Futures Commission of Hong Kong, and the Central Entity number is BSM562."])},
      "dooWealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited"])},
      "dooWealth-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, and the company registration number is 2507030."])},
      "dooWealth-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited is a licensed insurance broker company, authorized and regulated by the Hong Kong Insurance Authority (Insurance Broker License), and the regulatory number is FB1823."])},
      "dooHolding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooHolding-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong and the company registration number is 2169884."])},
      "dooHolding-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited is a licensed money service operator, authorized and regulated by the Hong Kong Customs and Excise Department (Money Service Operator License), and regulatory number is 18-06-02444."])},
      "dooProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Properties HK Limited"])},
      "dooProperties-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, and the company registration number is 3106018."])},
      "dooProperties-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Properties HK Limited is a real estate agency, regulated by the Hong Kong Estate Agents Authority, and the license number is C-094694."])},
      "dooConsulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooConsulting-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a Hong Kong trust company registered pursuant to the Trustee Ordinance (Cap. 29, Laws of Hong Kong) with the registered office address at 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, and the company registration number is 2601567."])},
      "dooConsulting-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited is a licensed trust or company service provider, authorized and regulated by the Hong Kong Companies Registry (Trust or Company Service Provider License), and the regulatory number is TC006709."])},
      "dooFintechHKLimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Fintech HK Limited"])},
      "dooFintechHKLimited-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered and physical office address at 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong and the company registration number is 2243363."])},
      "dooPrimeSey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooPrimeSey-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles and the company registration number is 8427202-1."])},
      "dooPrimeSey-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited is a licensed securities dealer, authorized and regulated by the Seychelles Financial Services Authority (SC FSA), and the regulatory number is SD090."])},
      "financialLabuan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "financialLabuan-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Level 2, Lot 19, Lazenda Commercial Centre Phase 3, 87000 FT Labuan, Malaysia, and the company registration number is LL17644."])},
      "financialLabuan-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited is a securities and money-broking business licensee, authorized and regulated by the Malaysia Labuan Financial Services Authority (MY Labuan FSA), and the license number are SL/23/0022 and MB/23/0108 respectively."])},
      "dooPrimeMaur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooPrimeMaur-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius and the company registration number is 172054."])},
      "dooPrimeMaur-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited is a licensed investment dealer, authorized and regulated by the Mauritius Financial Services Commission (MU FSC), and the regulatory number is C119023907."])},
      "dooPrimeVanu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooPrimeVanu-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Govant Building, Po Box 1276, Port Vila, Vanuatu and the company registration number is 700238."])},
      "dooPrimeVanu-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited is a licensed financial dealer, authorized and regulated by the Vanuatu Financial Services Commission (VU FSC), and the regulatory number is 700238."])}
    },
    "footerBottom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This website is owned and operated by Doo Technology Singapore Pte. Ltd., the technology arm of Doo Group. © ", _interpolate(_named("year")), " All Rights Reserved."])}
  },
  "mu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "milestones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestones"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlights"])},
    "mu_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["values"])},
    "image_with_text_box_strong_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["united"])},
    "image_with_text_box_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Partnership Mission"])},
    "image_with_text_box_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group, the leading fintech powerhouse, and Manchester United, the world’s most renowned football club have teamed up together to celebrate the importance of shared core values through our partnership. Come join our journey as Manchester United’s Official Online Financial Trading Platform Partner. "])},
    "landing_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United"])},
    "landing_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by shared values"])},
    "landing_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group And Manchester United: We Care About The Same Things You Do "])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "center_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venturing Into The Future"])},
    "center_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" As Partners"])},
    "center_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have many exciting initiatives in store as we move forward together. We are committed to delivering cutting-edge experiences to audiences around the world. Follow Doo Group and Manchester United as we take on the financial and sporting worlds together. "])},
    "first_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the Trading Floor To The Theatre Of Dreams "])},
    "first_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be part of history by capturing investments and scoring goals with us. "])},
    "second_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core Values"])},
    "second_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the synergy between Doo Group and Manchester United."])},
    "third_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership Highlights"])},
    "third_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay tuned for more exciting updates on our partnership with Manchester United."])},
    "fisrt_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importance Of Trust And Care "])},
    "fisrt_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Help Realise Dreams"])},
    "fisrt_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erik Ten Hag as the leader of Manchester United’s men first team has instilled a winning mentality back into the players. Trust of people and care in duty are core components in developing this ethos. Doo Group empowers its talented team of traders in the same way. Through trust, care and belief both Doo Group and Manchester United are striving to reach new heights. "])},
    "second_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Teamwork And Work Ethic "])},
    "second_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Achieving High Standards"])},
    "second_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group is on your team. We care about your financial future as much as you do and we work together as a team to invest safely. Just as the Manchester United team develops and evolves through their commitment to each other as a wider collective, understanding that no one is bigger than the team. Appreciating the responsibility and hard work required to continue to meet the high standards expected consistently. The Doo Group team also demonstrates this by drawing on our global talent pool to bring the best fintech services to all our clients on an ongoing basis."])},
    "third_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Football is nothing without fans – and investment companies are nothing without our clients. Manchester United respects its history and duty to entertain the millions of its community of fans around the world and what the club means to them. We are dedicated to our loyal client base and committed to providing every client with professional services, best in class experiences and continuously creating value for our clients.  "])},
    "third_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Importance Of People and Community "])},
    "third_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" For Future Success "])},
    "milestone_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winning Connection"])},
    "milestone_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This partnership is a pivotal milestone for Doo Group. After several  months of negotiation, we officially joined forces, kicking off the partnership with a soft launch in January 2023. "])},
    "milestone_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership Planning Workshop "])},
    "milestone_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goals and ambitions are closely aligned, which were further cemented with a dedicated Manchester United planning workshop for the partnership. Aligning on a core partnership mission with clear objectives to achieve through our activations which are fit for purpose. \n \n We share a passion for success and a commitment to delivering world-class experiences to audiences. "])},
    "milestone_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming To Light In May 2023 "])},
    "milestone_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group is ready to score big by reaching out to Manchester United's massive fanbase, including millions of passionate football enthusiasts and traders alike. Witness our Coming To Light event and experiences in Manchester, in May 2023! "])},
    "milestone_title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP Box Experience: Elevate Your Matchday"])},
    "milestone_description_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Doo Group extends a VIP Box Experience available to guests for 365 days, offering exclusive access to the hospitality area on match days. Guests indulge in gourmet food, champagne, and an opulent buffet. Moreover, the box displays partnership wallpaper, highlighting Doo Group's dedication to excellence."])},
    "milestone_title_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 9th Anniversary pitch side LED banner"])},
    "milestone_description_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group’s special edition LED was displayed at Manchester United’s home ground – Old Trafford."])},
    "latest_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Jobs"])},
    "our_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Jobs"])},
    "our_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Life"])},
    "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "esg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
    "blog_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Appointed As An Official Global Partner Of Manchester United"])},
    "blog_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group And Manchester United: United By Shared Values"])},
    "blog_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Man United To Honour Partnership With Grand Launch"])},
    "blog_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s history in the making as two giants cross paths for a monumental partnership with the event ‘Coming To Light’ this May 2023..."])},
    "blog_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Manchester United formed an alliance at the beginning of 2023 with a multi-year global partnership. The partnership brings football and..."])},
    "blog_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glory, glory Man United! Sing, cheer and rejoice as renowned football club Manchester United has officially established a multi-year global partnership..."])},
    "footerBottom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This website is owned and operated by Doo Group. © ", _interpolate(_named("year")), " Doo Group. All Rights Reserved."])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerships"])},
    "mufc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manchester United"])},
    "latest_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
    "hp-sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "hp-sg-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1736"])},
    "hp-hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "hp-hk-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 2632 9557"])}
  },
  "token2049": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Making Waves<br/>at Token2049 Dubai"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "tokenDubaiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://blog.doo.com/events/doo-group-makes-waves-at-token2049-dubai/"])}
  },
  "property": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transforming Your Property Experience Every Step of the Way"])},
    "doo-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Property"])},
    "doo-property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An extensive property management and solutions enhancing clients' property journey with insightful advice from our seasoned team across diverse areas, including property consultancy, property management and property brokerage services."])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Property business and explore more collaboration opportunities"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
  },
  "consulting": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving Business Success Via Strategic Solutions"])},
    "doo-consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting"])},
    "doo-consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A seamless global business consulting brand offering professional solutions such as company secretary, notarization and visa services for investors, ensuring streamlined operations and optimal efficiency."])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Consulting business and explore more collaboration opportunities"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
  },
  "cloud": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empowering Innovation with Cloud Excellence"])},
    "doo-cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Cloud"])},
    "doo-cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cutting-edge cloud solution at the forefront of business empowerment, offering top-tier products and services bolstered by advanced technology integration capabilities. Leveraging cloud computing, hardware expertise, and seamless IT integration, we propel businesses forward with unparalleled efficiency."])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Cloud business and explore more collaboration opportunities"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
  },
  "digitalMarketing": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Marketing"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevating Brand Presence in The Digital Era"])},
    "doo-digitalMarketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Digital Plus"])},
    "doo-digitalMarketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An innovative digital marketing brand committed to keeping business ahead in the dynamic digital landscape. Our expertise encompasses a wide range of services, including paid ads, performance & data analytics, SEO, website design/development, design/video productions, content/social media. "])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our Digital Marketing business and explore more collaboration opportunities"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
  }
}