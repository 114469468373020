export default {
  "seo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group"])},
  "seo_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索更美好的未來"])},
  "seo_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 是一家以金融科技為核心的金融服務集團，致力於為全球各地的個人及機構客戶提供超過 2 萬個證券、期貨、貨幣對、差價合約和基金等金融產品的交易經紀和資產管理服務。"])},
  "seo_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group,都會控股集團,全球金融市場,專業交易平台,證券,期貨,貨幣對,差價合約,基金交易"])},
  "common": {
    "language-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language:"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "sc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简体中文"])},
    "tc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繁體中文"])}
  },
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "group-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集团介绍"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集团里程碑"])},
    "regulatory-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监管牌照"])},
    "global-operational-centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球运营中心"])},
    "business-and-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业务和品牌"])},
    "business-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业务概览"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商业务"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财富管理"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兑换"])},
    "finTech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理财教育"])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入我们"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业务和品牌"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作伙伴"])},
    "esg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不动产"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询业务"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["云服务"])},
    "digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字营销"])}
  },
  "banner": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线金融交易平台合作伙伴"])},
    "headerDSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出联名信用卡 <br> ─ 升龙卡"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "header-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索美好未来"])},
    "header-sub-DSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无界支付，悦享专属礼遇"])},
    "bottom-text-DSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升龙卡由DCS Card Centre Pte. Ltd.发行"])},
    "description-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 是一家以金融科技为核心的国际金融服务集团，通过深度布局全球券商业务、财富管理、不动产、支付兑换、金融科技、理财教育、大健康、咨询服务、云服务和数字营销十大业务线，持续完善无界融合的商业生态圈，以一站式服务助您实现理想的金融生活，共赴美好未来！"])},
    "hongkongEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港金融科技周 2023 ：<br>Doo Group 精彩回顾"])},
    "hongkongEventDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "anniversary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group <br class='mobile'>九周年庆"])},
    "anniversaryDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同行 · 突破 ─ 探索美好未來"])},
    "ssf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡金融科技节<br>Doo Group 精彩回顾"])},
    "ssfDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tokenDubaiHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 即将精彩亮相<br>Token2049 迪拜峰会"])},
    "tokenDubaiDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎莅临 <span>M5 展位</span>"])},
    "tokenDubaiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://blog.doo.com/events/enhancing-doo-groups-presence-in-the-new-market-with-token-2049-dubai"])}
  },
  "stats": {
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户"])},
    "presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户覆盖国家/地区"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机构合作伙伴"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 全球运营中心"])}
  },
  "better-future": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十大业务"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 旗下十大业务主线覆盖金融服务的多个领域，为客户提供全方位的金融服务与创新解决方案，探索无限可能。"])},
    "title-brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商业务"])},
    "doo-clearing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing 旨在为机构客户提供证券、期货、外汇、差价合约等金融产品的流动性接入、订单执行、结算、托管及清算服务。"])},
    "doo-finance-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 旨在为专业投资者提供全球证券、期货、差价合约等金融产品的交易经纪服务，实现一键配置全球金融资产。"])},
    "doo-prime-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 旨在为专业投资者提供全球金融产品的差价合约交易经纪服务，致力于成为您的私人专属券商。"])},
    "title-wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财富管理"])},
    "doo-wealth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth旨在为投资者提供一站式的财富管理服务，覆盖保险、证券、全球基金、房地产、身份规划、信托及家族办公室，致力于打造更低门槛、更安全、更轻松的环球投资体验。"])},
    "title-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兑换"])},
    "doo-payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透过 Doo Payment 开设全球账户，以先进的金融科技轻松实现全球资金流动，高效把握数字时代的商业机遇。"])},
    "lesspay-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透过 LessPay 开设全球账户，以先进的金融科技轻松实现全球资金流动，高效把握数字时代的商业机遇。"])},
    "title-finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "doo-finpoints-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinPoints 通过将科技和创新深度融合，打造广泛、全面和先进的解决方案，用科技的力量革新全球金融业。"])},
    "title-financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理财教育"])},
    "financial-education-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Academy 旨在为广大投资爱好者提供准确的理财知识，拓宽个人投资视野，实现财富目标。"])},
    "title-health-care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "health-care-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Health 旨在为客户提供一站式的数字化大健康服务平台，打造更简单、更安全、更轻松的健康管理体验。"])},
    "title-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不动产"])},
    "doo-property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Property 致力于打造优质的房产交易和投资服务精准满足客户需求，提升客户的置业体验。"])},
    "title-consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询业务"])},
    "doo-consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting 提供量身定制的业务支持和多元化咨询服务，以畅通无阻的服务体验助力客户海外发展。"])},
    "title-cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["云服务"])},
    "doo-cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Cloud 集成领先技术打造满足全球市场需求的全包云服务解决方案，确保客户获得顶级的产品和服务体验。"])},
    "title-digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字营销"])},
    "doo-digital-marketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Digital Plus 通过创新的数字化营销方案，助力企业在数字化时代构建品牌影响力。"])}
  },
  "license": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际牌照"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 旗下实体共持有 20+ 监管牌照，在国际顶尖机构的严格监管下为全球客户提供安全可靠的多元化金融服务。每位客户的资金安全及交易透明度都得到专业第三方机构的保护，您可以安心选择我们作为您的金融合作伙伴。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "home-global-presence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球布局"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 在全球主要城市设有 20+ 运营中心，业务遍及全球，助您突破投资界限，把握无限机遇。"])}
  },
  "global-presence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接世界 布局全球"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 开启全球化布局，共设有 20 + 全球运营中心，拥有 1000 + 员工，专注优质本地化服务。"])}
  },
  "global-partnership": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球合作伙伴"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们与全球多领域的优质伙伴建立了长期合作关系，共建理想金融生态，实现共同发展和成功。"])}
  },
  "future-opportunity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期待您的联系 一同创造无限机遇"])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们诚挚欢迎任何的查询，不论是服务咨询还是商务合作，您均可与我们联系！"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])}
  },
  "latest-updates": {
    "latest-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "esg": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG 行动"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["践行 ESG 理念，以可持续发展为目标，在集团稳步发展的同时，为促进社会发展、实现美好未来不遗余力。"])}
  },
  "about": {
    "group-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集团介绍"])},
    "group-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元金融服务，一站达至美好未来"])},
    "betterFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索美好未来"])},
    "betterFutureDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 都会控股集团成立于 2014 年，总部位于新加坡，是一家以金融科技为核心的国际金融服务集团，旗下拥有券商业务、财富管理、不动产、支付兑换、金融科技、理财教育、大健康、咨询服务、云服务和数字营销十大业务线。通过各领域业务的协同发展，Doo Group 持续完善商业生态圈，致力于为全球客户提供全方位的金融服务与创新解决方案，共同探索美好未来。"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使命"])},
    "missionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为客户提供安全可靠、专业、便捷的交易经纪和资产管理服务。"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愿景"])},
    "visionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为一家以金融科技为核心、领先全球的金融服务集团。"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价值观"])},
    "values-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户为先"])},
    "values-1-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们致力于为每一位客户提供专业的服务、满意的体验，持续地为客户创造价值。"])},
    "values-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["诚信、可靠及公平公正"])},
    "values-2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为一家金融公司，确保客户资产安全及权益受到公平公正的对待，是我们的核心责任。"])},
    "values-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企业社会责任"])},
    "values-3-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从创立开始，我们就不认为盈利是企业的唯一目标。我们将敢于承担应有的企业社会责任，致力为个人、行业和社会的发展持续带来贡献。"])},
    "businessAndBrands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多元化业务和品牌"])},
    "businessAndBrandsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的团队遍布世界各地，并持续吸纳优秀人才。我们拥有多个子品牌，并在不同的业务范畴上提供服务和方案。立即查看我们的品牌和服务！"])},
    "financialTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "milestone-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集团里程碑"])},
    "milestone-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不断创新变革，铸就荣耀历程，每一步都有迹可循"])},
    "developmentJourney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的发展历程"])},
    "developmentJourneyDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 自 2014 年创立至今，发展十大业务线，布局全球市场，构建多元金融生态体系，持续为全球 200,000+ 客户提供完善的金融服务与创新解决方案。"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌发展"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球布局"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品革新"])},
    "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全监管"])},
    "m-2014-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 成立，以金融科技集团模式运营"])},
    "m-2014-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立核心研发及技术支持团队"])},
    "m-2014-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立中国香港办公室"])},
    "m-2015-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引入国际认可的专业交易软件 MT4，提供热门货币对产品"])},
    "m-2016-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立韩国首尔办公室"])},
    "m-2016-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拓展贵金属、能源及全球主要股票交易指数"])},
    "m-2016-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 BrokerForce v3.0，全面提升系统稳定性及用户体验"])},
    "m-2017-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Clearing 清算品牌，为经纪商提供优质的流动性接入与清算服务"])},
    "m-2017-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立英国伦敦办公室"])},
    "m-2017-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得瓦努阿图金融服务委员会 FSC 牌照"])},
    "m-2017-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索引入新一代交易软件 MT5，支持更多强大功能"])},
    "m-2017-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次推出 Bridge Cloud，为经纪商提供顶级流动性清算桥接服务"])},
    "m-2018-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合零售客户业务，推出全新品牌 Doo Prime"])},
    "m-2018-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立中国台北办公室"])},
    "m-2018-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立马来西亚吉隆坡办公室"])},
    "m-2018-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港海关香港金钱服务经营者牌照"])},
    "m-2018-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo MT5 交易平台正式启用，更强大更轻松，革新用户交易体验"])},
    "m-2018-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BrokerForce v 4.0 升级亮相"])},
    "m-2019-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机构客户达到 400+"])},
    "m-2019-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立新加坡办公室"])},
    "m-2019-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立毛里求斯办公室"])},
    "m-2019-g3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立塞舌尔办公室"])},
    "m-2019-g4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立瓦努阿图办公室"])},
    "m-2019-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得英国金融行为监管局 FCA 牌照"])},
    "m-2019-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港公司注册处 TCSP 牌照"])},
    "m-2019-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得加拿大金融交易和报告分析中心 FINTRAC 牌照"])},
    "m-2019-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不断拓展交易品种，接入更丰富美股、期货交易产品"])},
    "m-2019-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与多家金融科技公司合作推出 InTrade 交易终端 及 Outrade 社区化交易平台"])},
    "m-2019-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联通 TradingView 云端交易平台，协助客户交流海量的图表指标和交易想法"])},
    "m-2019-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与 Myfxbook 建立战略合作伙伴关系，强势助推 AutoTrade 镜像交易系统"])},
    "m-2020-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收购拥有 30 年历史的美国券商 Peter Elish"])},
    "m-2020-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 FinPoints 一站式金融技术提供品牌"])},
    "m-2020-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与Trading Central 建立合作伙伴关系，打造更多样更全面的专业技术分析工具"])},
    "m-2020-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立美国达拉斯办公室"])},
    "m-2020-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立越南胡志明市办公室"])},
    "m-2020-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得美国证监会 SEC 及金融业监管局 FINRA 牌照"])},
    "m-2020-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得毛里求斯金融服务委员会 FSC 牌照"])},
    "m-2020-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买了专业责任赔偿保险 (PII)，全方位保障客户资产安全和自身权益"])},
    "m-2020-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 300+ 个明星美港股及期货产品"])},
    "m-2020-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进驻全球领先的外汇交易社区 FOLLOWME，携手提升客户的社交交易体验"])},
    "m-2020-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与一站式金融教育平台 MTE Media 联手提供海量的投资者教育资源"])},
    "m-2020-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作打造 Price Cloud 报价分发系统，提供极速稳定的多个市场报价接入及整合服务"])},
    "m-2020-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供 VPS 交易专用服务器，为客户建构更安全稳定的交易环境"])},
    "m-2021-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Financial 互联网券商品牌​"])},
    "m-2021-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户达 40,000+​"])},
    "m-2021-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机构伙伴达到 250+​"])},
    "m-2021-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 与 盈透建立全披露券商关系​"])},
    "m-2021-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与 oneZero 建立合作伙伴关系​"])},
    "m-2021-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与 Abans 建立战略合作伙伴关系​"])},
    "m-2021-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与 Bison Bank 签订合作备忘录 MOU​"])},
    "m-2021-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 收购澳大利亚经纪商 Kinsmen Securities Limited​"])},
    "m-2021-b9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["于英国伦敦国王学院成立 “Doo” 助学金​"])},
    "m-2021-b10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 推出“慈善之星” 计划​"])},
    "m-2021-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立泰国曼谷办公室​"])},
    "m-2021-g2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立阿联酋迪拜办公室"])},
    "m-2021-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得澳大利亚证券和投资委员会 ASIC 金融交易牌照"])},
    "m-2021-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得塞舌尔金融服务局 FSA 牌照"])},
    "m-2021-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作推进 Clearing Cloud 大型多边清算系统，协助业内不同规模的经纪商连接流动性提供商"])},
    "m-2021-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为交易者提供超过10,000款交易产品"])},
    "m-2021-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Prime TradingView 交易界面"])},
    "m-2021-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Prime InTrade 2.1.0 版本"])},
    "m-2021-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 推出 3.0 版本官网、媒体中心、帮助中心"])},
    "m-2021-p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 推出泰国版积分商城"])},
    "m-2022-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Wealth 财富管理品牌"])},
    "m-2022-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户达 60,000+"])},
    "m-2022-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial 收购财富管理公司 Doo Wealth Management HK Limited"])},
    "m-2022-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为阿德莱德联足球俱乐部官方合作伙伴"])},
    "m-2022-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携手 UNICEF HK 促进儿童福祉"])},
    "m-2022-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开放日本、印度尼西亚市场"])},
    "m-2022-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港保险业监管局 IA 牌照"])},
    "m-2022-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获批香港证券及期货事务监察委员会  SFC 第 4 类和第 9 类牌照"])},
    "m-2022-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港地产管理监管局 EAA 牌照"])},
    "m-2022-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得澳大利亚交易报告和分析中心 AUSTRAC 牌照"])},
    "m-2022-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推出 Doo Prime InTrade 2.6.4 版本"])},
    "m-2022-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 上线 MT5 交易平台"])},
    "m-2022-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime CopyTrading 正式上线"])},
    "m-2023-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户达 200,000+"])},
    "m-2023-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Payment 支付兑换品牌"])},
    "m-2023-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Academy 理财教育品牌"])},
    "m-2023-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Health 大健康品牌"])},
    "m-2023-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成为曼联足球俱乐部官方全球合作伙伴"])},
    "m-2023-b6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成为西澳大利亚州澳中友好协会（ACFSWA）荣誉赞助商"])},
    "m-2023-b7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成为雪隆痉孪儿童协会黄金赞助商"])},
    "m-2023-b8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Payment 收购汇款和数字货币兑换提供商 Doo Exchange AU Pty Ltd"])},
    "m-2023-g1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立澳大利亚悉尼办公室和客服中心"])},
    "m-2023-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港公司注册处香港注册信托公司牌照"])},
    "m-2023-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得马来西亚纳闽金融服务管理局  FSA 牌照"])},
    "m-2023-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得迪拜房地产监管局 RERA 牌照"])},
    "m-2023-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得美国金融犯罪执法局 FinCEN 牌照"])},
    "m-2024-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Property 不动产品牌"])},
    "m-2024-b2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Consulting 咨询业务品牌"])},
    "m-2024-b3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Cloud 云服务品牌"])},
    "m-2024-b4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布  Doo Digital Plus 数字营销品牌"])},
    "m-2024-b5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户覆盖国家/地区达150+"])},
    "m-2024-s1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港证券及期货事务监察委员会  SFC 第 1 类牌照"])},
    "m-2024-s2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得新加坡金融管理局 MAS 牌照"])},
    "m-2024-s3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得迪拜经济和旅游部 PSP 牌照"])},
    "m-2024-s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得阿联酋中央银行 CBUAE 保险经纪牌照"])},
    "m-2024-s5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得澳大利亚证券与投资委员会 ASIC 支付服务牌照"])},
    "m-2024-s6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得香港海关贵金属及宝石交易商的监理科 DPMS 牌照"])},
    "license-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["监管牌照"])},
    "license-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 始终秉承“客户为先”理念，不断完善金融生态，受国际顶尖金融监管机构严格监管，保障客户资产安全"])},
    "strictSupervision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["严格监管 多重保障"])},
    "strictSupervisionDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 作为国际领先的金融服务集团，目前已在全球多个国家获得 20+ 张牌照，不断完善全球金融监管格局​。"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "allCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商业务"])},
    "wealthManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财富管理"])},
    "paymentExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兑换"])},
    "US_SEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US SEC​<br>美国证券交易委员会"])},
    "US_SEC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：SEC 8-41551​"])},
    "US_FINRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US FINRA​<br>美国金融业监管局​"])},
    "US_FINRA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：CRD 24409​"])},
    "UK_FCA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK FCA​<br>英国金融行为监管局"])},
    "UK_FCA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：833414​"])},
    "AU_ASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIC​<br>澳大利亚证券与投资委员会​ (金融交易）"])},
    "AU_ASIC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：222650​"])},
    "AU_AUSTRAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUSTRAC​<br>澳大利亚交易报告和分析中心"])},
    "AU_AUSTRAC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独立汇款：IND100812107-001​<br>数字货币交易所：DCE100812107-001"])},
    "HK_SFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK SFC​<br>香港证券及期货事务监察委员会（第 1 、4、9 类牌照）"])},
    "HK_SFC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：BSM562​"])},
    "HK_Insurance_Broker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK Insurance Broker​<br>香港保险业监管局​（香港保险经纪牌照）"])},
    "HK_Insurance_Broker_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：FB1823​"])},
    "HK_Trust_Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK Trust Company​<br>香港公司注册处（香港注册信托公司）"])},
    "HK_Trust_Company_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司编号：2601567​​"])},
    "HK_MSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK MSO​<br>香港海关​（香港金钱服务经营者牌照）"])},
    "HK_MSO_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：18-06-02444​​"])},
    "HK_EAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK EAA​<br>香港地产管理监管局"])},
    "HK_EAA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：C-094694​​"])},
    "SC_FSA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SC FSA​<br>塞舌尔金融服务管理局"])},
    "SC_FSA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：SD090"])},
    "MY_Labuan_FSA-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY Labuan FSA​<br>马来西亚纳闽金融服务管理局"])},
    "MY_Labuan_FSA-1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证券牌照编号：SL/23/0022"])},
    "MY_Labuan_FSA-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY Labuan FSA​<br>马来西亚纳闽金融服务管理局"])},
    "MY_Labuan_FSA-2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融货币经纪牌照编号：MB/23/0108"])},
    "MU_FSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MU FSC​<br>毛里求斯金融服务委员会"])},
    "MU_FSC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号: C119023907​"])},
    "VU_FSC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VU FSC​<br>瓦努阿图金融服务委员会​"])},
    "VU_FSC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号: 700238​"])},
    "US_FINCEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US FinCEN​<br>美国金融犯罪执法局"])},
    "US_FINCEN_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSB 注册编号: 31000255627030"])},
    "CA_FINTRAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA FINTRAC​<br>加拿大金融交易和报告分析中心"])},
    "CA_FINTRAC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSB Registration Number: M19501973"])},
    "ASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIC​<br>澳大利亚证券与投资委员会 (支付服务)"])},
    "ASIC_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：444062"])},
    "SG_MAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG MAS<br>新加坡金融管理局"])},
    "SG_MAS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：CMS101428"])},
    "HK_DPMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK DPMS<br>香港海关（贵金属及宝石交易商的监理科）"])},
    "HK_DPMS_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：A-B-24-04-06541"])},
    "HK_TCSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HK TCSP<br>香港公司注册处（香港信托或公司服务提供者牌照）"])},
    "HK_TCSP_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司编号：TC006709"])},
    "AE_RERA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE RERA<br>迪拜房地产监管局"])},
    "AE_RERA_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：1172628"])},
    "CBUAE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CBUAE<br>阿联酋中央银行保险经纪牌照"])},
    "CBUAE_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：226"])},
    "AE_PSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AE PSP<br>迪拜经济和旅游部"])},
    "AE_PSP_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌照编号：1312503"])},
    "global-office-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球运营中心"])},
    "global-office-sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 视服务全球投资者为使命，不断扩大全球业务版图，满足全球客户需求"])},
    "global-operations-centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带您游览我们的全球运营中心"])},
    "hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伦敦"])},
    "kl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["吉隆坡"])},
    "sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "jlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迪拜（JLT）"])},
    "ems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迪拜（Emaar Square）"])}
  },
  "business-overview": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业务概览"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 发展多元化业务，引领客户走向更美好的未来！"])},
    "six-major-business-lines-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布局十大业务领域<br>构建无界融合的商业生态圈"])},
    "six-major-business-lines-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 以“无界”概念重新构想金融生态的更多可能性，打造无界融合的商业生态圈。基于领先的技术支持，Doo Group 致力于通过不断的创新和变革，创造出一个潜力无限的未来。"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商业务"])},
    "brokerage-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为机构客户和专业投资者提供证券、期货、货币对和差价合约等金融产品的交易经纪服务，一键配置全球金融资产。​"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财富管理"])},
    "wealth-management-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于客户的财富目标与风险偏好，提供专业的财富顾问服务及一站式投资理财方案，实现资产的有效管理、增值和传承。​"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兑换"])},
    "payment-exchange-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以量身定制的全球支付解决方案打造未来支付场景，为客户提供高效、便捷的资金管理体验。​"])},
    "fintech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "fintech-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以领先金融科技全面支持客户的业务发展，提供清算系统、市场数据等多元化的金融科技解决方案。​​"])},
    "financial-education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理财教育"])},
    "financial-education-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打造专业的理财教育平台，以丰富的理财知识助力客户做出明智的投资决策，实现财富目标。​​"])},
    "health-care": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "health-care-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康是最宝贵的财富，我们致力于通过精准医疗服务，助力客户找到更健康、更美丽的自己。​​"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不动产"])},
    "property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结合专业建议、高效管理模式以及成熟房产经纪经验，以优质的房产交易和投资服务精准满足客户需求。"])},
    "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询业务"])},
    "consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据客户需求量身定制业务支持和多元化咨询服务，以畅通无阻的服务体验助力客户海外发展。"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["云服务"])},
    "cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集成先进技术的领先云服务产品和解决方案，为满足全球市场需求而设计。"])},
    "digital-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字营销"])},
    "digital-marketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以创新的数字营销解决方案实现精准营销，助力企业拓展全球品牌影响力。"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎联系我们"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解多元业务详情，或探索更多合作机遇​"])},
    "contact-us-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写表格"])}
  },
  "brokerage": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商业务"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键配置全球金融资产"])},
    "doo-clearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing"])},
    "doo-clearing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一家流动性提供商，旨在为机构客户提供证券、期货、外汇、差价合约等金融产品的流动性接入、订单执行、结算、托管及清算服务。"])},
    "doo-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial"])},
    "doo-financial-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为准求高监管标准的专业客户提供全球证券、期货、外汇、差价合约和基金交易的一站式投资服务。"])},
    "doo-prime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime"])},
    "doo-prime-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一家全球领先的互联网券商，为专业投资者提供全球证券、期货、货币对、贵金属、大宗商品和股票指数的差价合约交易产品。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解券商业务详情，或探索更多合作机遇"])}
  },
  "wealth-management": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财富管理"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一站直达理想财富未来"])},
    "doo-wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth"])},
    "doo-wealth-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透过完善的全球财富管理服务体系，满足客户的多元需求。Doo Wealth 顶级投顾团队在保险、证券、全球基金、不动产、身份规划、信托和家族办公室等领域拥有丰富的经验，可根据客户的风险承受能力和财富目标提供量身定制的解决方案。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解财富管理业务详情，或探索更多合作机遇"])}
  },
  "payment-exchange": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兑换"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随心实现全球资金流动"])},
    "doo-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Payment"])},
    "doo-payment-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个支持多种货币的支付兑换业务品牌，通过智能化金融科技设施打破地域界限，为客户提供更便捷、更高效的国际汇款方式。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解支付兑换业务详情，或探索更多合作机遇"])}
  },
  "finTech": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领先科技开创金融新时代"])},
    "finpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FinPoints"])},
    "finpoints-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球领先的金融科技提供商，将技术和创新深度融合，提供全面的解决方案和产品，包括关键级金融云服务器、市场数据分发系统、订单管理系统、市场数据和交易客户端。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解金融科技业务详情，或探索更多合作机遇"])}
  },
  "financial-education": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理财教育"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让投资理财成就价值人生"])},
    "doo-academy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Academy"])},
    "doo-academy-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打造全面的理财教育平台，通过线上/线下课程、名师直播、专属研习小组等一系列服务和产品，为客戶提供专业的理财资讯。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解理财教育业务详情，或探索更多合作机遇"])}
  },
  "healthcare": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大健康"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["健康科技赋能多彩人生"])},
    "doo-health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Health"])},
    "doo-health-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个专注于精准医疗与个性化健康的大健康品牌，提供健康检查​、疫苗接种、抗衰老和干细胞解决方案，引领健康管理新时代。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解大健康业务详情，或探索更多合作机遇"])}
  },
  "contact_us": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎留下您的联系方式，Doo Group 专属顾问会在 1 个工作日内与您联系。"])},
    "time_zone_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一流的国际客户服务"])},
    "time_zone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自全球不同国家和地区的 200+ 客服及 300+ 技术人员 提供的全天候多语言的顶级服务支援"])},
    "new_york": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纽约"])},
    "london": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伦敦"])},
    "south_africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南非"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塞浦路斯"])},
    "dubai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迪拜"])},
    "hong_kong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["澳大利亚"])},
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留下您的联系方式"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话 (包括国家编号)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮地址"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机构/公司名称"])},
    "service_consultation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务咨询"])},
    "business_cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商务合作"])},
    "media_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["传媒采访"])},
    "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询类型"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择希望查询的品牌（可多选）"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["留言"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即提交"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), "为必填项"])},
    "number_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("title")), "必须是数字"])},
    "email_notValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的电子邮件地址"])},
    "message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要留言"])},
    "hCaptcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写 hCaptcha"])},
    "submit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感謝您的留言。 已發送。"])},
    "submit_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訊息發送失敗。"])},
    "checkbox_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请至少选择一项。"])}
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "doogroup.com"])},
    "hp-sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "hp-sg-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1736"])},
    "hp-hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "hp-hk-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 2632 9557"])},
    "follow-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关注我们"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "group-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集团介绍"])},
    "milestone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集团里程碑"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["監管牌照"])},
    "global-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球办公室"])},
    "join-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入我们"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业务和品牌"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业务概览"])},
    "brokerage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["券商业务"])},
    "wealth-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财富管理"])},
    "payment-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付兑换"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金融科技"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌合作"])},
    "our-partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作伙伴"])},
    "Mu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曼联"])},
    "Au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿德雷德联"])},
    "ESG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒体中心"])},
    "compliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合规与风险披露"])},
    "brand-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌和实体公告"])},
    "client-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户须知"])},
    "entities-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo 实体公告"])},
    "body": {
      "compliance-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本網站由 Doo Group 旗下的 Doo Technology Singapore Pte. Ltd.擁有和運營。"])},
      "compliance-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Technology Singapore Pte. Ltd. 公司注册地址为 1 Temasek Avenue, #24 – 01A, Millenia Tower, Singapore 039192，公司注册号为 202104581D。"])},
      "compliance-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该网站可在全球范围内访问，并不特定于任何实体。这主要是为了信息集中展示和对比的便利，你的实际权利和义务会基于你所选择的实体从监管所获得的授权和监管所决定。"])},
      "compliance-description-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须至少年满18岁，然后才能访问我们的网站产品和服务。 通过访问我们的网站，您确认您符合了年龄的要求。"])},
      "compliance-description-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有些当地法律法规禁止或限制您访问，下载，分发，传播，共享或以其他方式使用本网站上发布的任何或所有文档和信息的权利。"])},
      "compliance-description-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于基础金融工具的价值和价格会有剧烈变动，股票，证券，期货，差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。"])},
      "compliance-description-seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去的投资表现并不代表其未来的表现。"])},
      "compliance-description-eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议或推荐。在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立的专业建议。"])},
      "brand-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing, Doo Financial, Doo Wealth, Doo Payment and Exchange, LessPay, FinPoints, Doo Academy, Doo Health, Peter Elish 和 Doo Consulting是Doo Group 所拥有的商用名称、注册商标和知识产权。"])},
      "brand-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们有各种受监管和许可的实体提供相关的金融服务。 您可以与我们的任何实体建立业务关系。 所选择的实体意味着您的交易账户，交易活动和资金只由这特定的监管机构进行监管。"])},
      "brand-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们不向某些辖区的个人和公司提供服务，包括但不限于阿富汗，阿塞拜疆，波斯尼亚和黑塞哥维那，缅甸，加拿大，刚果，古巴，埃塞俄比亚，圭亚那，海地，香港，伊朗，伊拉克，以色列，日本，黎巴嫩，马来西亚，马里，朝鲜，新加坡，斯里兰卡，索马里 ，特立尼达和多巴哥，突尼斯，委内瑞拉，阿拉伯联合酋长国，美国。 我们网站上的信息和服务不适用于在此类信息和服务的发布违反各自当地法规的国家或司法管辖区。 来自上述地区的访客应在使用我们的服务之前，确认您对我们的服务进行投资的决定是否符合您居住国家/地区的法规。 我们保留随时更改，修改或终止我们的产品和服务的权利。"])},
      "client-description-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议。 本网站上提供的任何评论，陈述，数据，信息，材料或第三方材料（“材料”）仅供参考。 该材料仅被认为是市场传播，不包含，也不应被解释为包含任何交易的投资建议和/或投资建议。 尽管我们已尽一切合理的努力确保信息的准确性和完整性，但我们对材料不做任何陈述和保证，如果所提供信息的任何不准确和不完整，我们也不对任何损失负责，包括但不限于利润损失，直接或间接损失或损害赔偿。 未经我们的同意，您只能将该材料用于个人用途，不得复制，复制，重新分发和/或许可该材料。"])},
      "client-description-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们使用我们网站上的cookies来根据您的喜好自定义我们网站上显示的信息和体验。 通过访问本网站，您承认您已经阅读并同意上述详细信息，并同意我们使用cookies。"])},
      "client-description-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们完全遵守司法管辖区中所有适用的法律和法规。 请确保您的投资符合要求是您的责任，您将承担投资和交易的所有后果。"])},
      "client-description-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本网站由 Doo Group 持有和运营。© 2023 Doo Group。版权所有。"])},
      "ellish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "ellish-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States的公司。"])},
      "ellish-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc 是一家受美国证券交易委员会（US SEC）和金融业监管局 (US FINRA) 监管的经纪商， 监管编号分别为 SEC: 8-41551和CRD: 24409。"])},
      "ellish-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities 是Elish & Elish Inc 授权的商业名称。"])},
      "clearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "clearing-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF 的公司， 公司注册编号为10684079。"])},
      "clearing-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited 是一家期货、外汇经纪商和流动性供应商，受英国金融行为监管局（UK FCA）授权和监管， 监管编号为833414。"])},
      "financialAus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "financialAus-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于  'Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia 的公司， 澳大利亚公司编号为100 139 820。"])},
      "financialAus-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited 是持有澳大利亚金融服务牌照的主体，受澳大利亚证券与投资委员会（ASIC）授权和监管， 监管编号为222650。"])},
      "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "exchange-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 'Tower 1' Suite 1302 Level 13, 475 Victoria Avenue, Chatswood NSW 2067, Australia 的公司， 澳大利亚公司编号为 661 717 237。"])},
      "exchange-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd 是货币兑换服务和汇款提供商，受澳大利亚交易报告和分析中心（AUSTRAC）授权和监管， 授权编号分别为 IND100812107-001 和 DCE100812107-001。"])},
      "financialHk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial HK Limited"])},
      "financialHk-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司注册编号为 2099737。"])},
      "financialHk-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial HK Limited 获准从事第4类（就证券提供意见）和第9类（提供资产管理）业务，受香港证券及期货事务监察委员会监管， 中央编号为 BSM562。"])},
      "dooWealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited"])},
      "dooWealth-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司注册编号为2507030。"])},
      "dooWealth-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited是一家持牌保险经纪公司，受香港保险业监管局（保险经纪牌照）授权和监管， 监管编号为FB1823。"])},
      "dooHolding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooHolding-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司注册编号为2169884。"])},
      "dooHolding-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited 是一家持牌货币服务运营商，受香港海关（金钱服务经营者牌照）授权和监管， 监管编号为18-06-02444。"])},
      "dooProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Properties HK Limited"])},
      "dooProperties-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司注册编号为3106018。"])},
      "dooProperties-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Properties HK Limited 是一家地产代理公司，受香港地产代理监管局监管， 牌照编号为 C-094694。"])},
      "dooConsulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooConsulting-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家根据香港法例第29章《受托人条例》注册的香港信托公司，注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司， 公司注册编号为 2601567 。"])},
      "dooConsulting-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited 是一家持牌信托或公司服务提供者，受香港公司注册处（信托或公司服务提供者牌照）授权和监管， 牌照编号为 TC006709。"])},
      "dooFintechHKLimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Fintech HK Limited"])},
      "dooFintechHKLimited-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册及办公地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司, 公司注冊編號為 2243363。"])},
      "dooPrimeSey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooPrimeSey-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles 的公司， 公司注册编号为8427202-1。"])},
      "dooPrimeSey-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited 是一家持牌证券交易商，受塞舌尔金融服务管理局（SC FSA）授权和监管， 监管编号为SD090。"])},
      "financialLabuan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "financialLabuan-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Level 2, Lot 19, Lazenda Commercial Centre Phase 3, 87000 FT Labuan, Malaysia 的公司, 公司注册编号为 LL17644。"])},
      "financialLabuan-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited 是持有证券和金融货币经纪牌照的主体，受马来西亚纳闽金融服务管理局（MY Labuan FSA） 授权和监管，监管编号分别为 SL/23/0022 和 MB/23/0108。"])},
      "dooPrimeMaur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooPrimeMaur-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius 的公司, 公司注册编号为172054。"])},
      "dooPrimeMaur-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited 是一家持牌投资交易商，受毛里求斯金融服务委员会（MU FSC）授权和监管， 监管编号为 C119023907。"])},
      "dooPrimeVanu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooPrimeVanu-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Govant Building, Po Box 1276, Port Vila, Vanuatu 的公司, 公司注册编号为700238。"])},
      "dooPrimeVanu-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited 是一家持牌金融交易商，受瓦努阿图金融服务委员会（ VU FSC）授权和监管， 监管编号为700238。"])}
    },
    "footerBottom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本网站由 Doo Group 旗下的 Doo Technology Singapore Pte. Ltd.拥有和运营。 © ", _interpolate(_named("year")), " 版权所有"])}
  },
  "mu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作关系"])},
    "milestones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["里程碑"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光时刻"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "mu_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共同价值观"])},
    "image_with_text_box_strong_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["攜手同行 "])},
    "image_with_text_box_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作使命"])},
    "image_with_text_box_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 作为国际领先的金融服务集团，与世界最著名的足球俱乐部——曼彻斯特联足球俱乐部达成战略合作，我们将在共同价值观的驱使下开展一系列合作。 Doo Group 作为曼联的官方在线金融交易平台合作伙伴，诚邀您加入我们的精彩之旅。 "])},
    "landing_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以共同价值观"])},
    "landing_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创造未来"])},
    "landing_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 与曼联：志同道合 共谋发展"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "center_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携手并进"])},
    "center_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共创未來 "])},
    "center_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在我们携手并进的历程中，我们将共同创造许多令人激动人心的时刻。 加入 Doo Group 和曼联，让我们共同努力，创造无限精彩！ "])},
    "first_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从交易场到梦剧场"])},
    "first_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们一同见证投资交易与足球破门的历史性时刻"])},
    "second_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["核心价值观"])},
    "second_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索 Doo Group 与曼联之间的协作价值 "])},
    "third_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光时刻"])},
    "third_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欲了解 Doo Group 与曼联合作更多内容，请持续关注。"])},
    "fisrt_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信任和关怀"])},
    "fisrt_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对实现梦想的重要性"])},
    "fisrt_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["埃里克·滕哈格作为曼联队的主帅，向球员们重新灌输曼联精神——为胜利而战，球员间的信任和至高的责任感是其精神内核。 Doo Group  以同样的方式赋予其优秀的交易团队以力量。通过信任、关怀和信念， Doo Group 和曼联都将不断突破，开创新的高度。"])},
    "second_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["践行高标准的"])},
    "second_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团队协作和职业道德 "])},
    "second_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 与您同在。我们和您一样重视财富未来和投资安全。正如曼联始终坚信没有什么比团队更重要一样，曼联球队的发展和进步离不开团队合作和坚定的信念。我们深知践行高标准的团队合作和职业道德所需要的责任和努力。为此我们持续不断培养遍布世界的行业精英，致力于为全球投资者提供极致的金融科技服务。"])},
    "third_image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有球迷，足球就失去了意义。对 Doo Group 来说同样如此。曼联始终铭记其历史使命，深知俱乐部对球迷的重要意义，并致力于为全世界数百万球迷提供娱乐。我们始终视服务全球投资者为使命，始终秉承的服务宗旨是：致力于为每一位客户提供专业的服务、满意的体验，持续地为您创造价值。"])},
    "third_image_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个体与团体"])},
    "third_image_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对未来成功的重要性 "])},
    "milestone_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作共赢"])},
    "milestone_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历时几个月的从点到面的充分沟通，Doo Group 与曼联于 2023 年 1 月正式拉开合作序幕。Doo Group 突破发展，再创新纪元。"])},
    "milestone_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 合作研讨会"])},
    "milestone_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们与曼联开展了专项合作研讨会，明确了共同的发展目标与愿景，巩固加深了伙伴关系。   未来我们将秉承核心的合作使命，开展一系列精彩活动，朝着共同的目标迈进。\n\n我们将满怀激情，携手为全球用户带来极致体验。 "])},
    "milestone_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 年 5 月 “光·启未来”合作盛典 "])},
    "milestone_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 将通过曼联庞大的球迷群体——携手数以亿计的足球爱好者和投资者开创新征程。2023 年 5 月，一同在曼彻斯特见证我们“光·启未来”合作盛典的盛大和精彩！"])},
    "milestone_title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group VIP 包厢：带给您奢华观赛体验"])},
    "milestone_description_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 将为客人提供全年的 VIP 包厢服务，为其在比赛日提供专属观赛区。客人可在 VIP 包厢尽享豪华自助餐美食和香槟美酒，并通过合作伙伴展示墙进一步了解  Doo Group 追求卓越的品牌内核。"])},
    "milestone_title_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 9 周年 LED 展示屏"])},
    "milestone_description_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 9 周年特别版视频在曼联主场老特拉福德球场的 LED 展示屏上展出"])},
    "latest_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Jobs"])},
    "our_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Jobs"])},
    "our_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Life"])},
    "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "esg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
    "blog_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group Appointed As An Official Global Partner Of Manchester United"])},
    "blog_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group And Manchester United: United By Shared Values"])},
    "blog_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Man United To Honour Partnership With Grand Launch"])},
    "blog_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s history in the making as two giants cross paths for a monumental partnership with the event ‘Coming To Light’ this May 2023..."])},
    "blog_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group and Manchester United formed an alliance at the beginning of 2023 with a multi-year global partnership. The partnership brings football and..."])},
    "blog_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glory, glory Man United! Sing, cheer and rejoice as renowned football club Manchester United has officially established a multi-year global partnership..."])},
    "footerBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本网站由 Doo Group 持有和运营。© 2023 Doo Group。版权所有。"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌合作"])},
    "latest_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
    "mufc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["曼联"])},
    "hp-sg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡"])},
    "hp-sg-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1736"])},
    "hp-hk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港"])},
    "hp-hk-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 2632 9557"])}
  },
  "token2049": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token2049 迪拜峰会<br/>Doo Group 破浪前行"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "tokenDubaiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://blog.doo.com/sc/events/doo-group-makes-waves-at-token2049-dubai/"])}
  },
  "property": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不动产"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开启卓越房地产投资之旅"])},
    "doo-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Property"])},
    "doo-property-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由资深团队提供一站式房地产开发服务以及房屋托管和房产经纪人业务，旨在为投资者提供优质的房产交易和投资体验。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解不动产业务详情，或探索更多合作机遇"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "consulting": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询业务"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["战略规划布局全球业务"])},
    "doo-consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting"])},
    "doo-consulting-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["综合的全球商务咨询品牌，通过简化流程提供高效的公司秘书、公证认证、移民签证等专业解决方案。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解咨询业务详情，或探索更多合作机遇"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "cloud": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["云服务"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以卓越云技术助力创新发展"])},
    "doo-cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Cloud"])},
    "doo-cloud-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由先进的云计算、硬件设施和无缝 IT 集成技术支持的顶级产品和服务，助力企业通过前沿的云服务解决方案实现高效发展。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解云服务业务详情，或探索更多合作机遇"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "digitalMarketing": {
    "banner-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字营销"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开启品牌数字营销大时代"])},
    "doo-digitalMarketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Digital Plus"])},
    "doo-digitalMarketing-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过创新的数字化营销方案，助力企业在数字化时代构建品牌影响力。我们提供全面的数字营销方案，涵盖付费广告、数据分析、SEO、网站设计及开发、设计/视频制作、内容及社交媒体营销等。"])},
    "contact-us-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进一步了解数字营销业务详情，或探索更多合作机遇"])},
    "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
  },
  "aufc": {
    "aufc-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
    "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光时刻"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高光时刻"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作伙伴"])},
    "community-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作伙伴"])},
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携手共创未来"])},
    "banner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 和阿德莱德联队努力追求更高目标"])},
    "highlights-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索两家巨头的悠久历史和辉煌成就"])},
    "DooGroup-2023-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户达 200,000+"])},
    "DooGroup-2023-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Payment 支付兑换品牌"])},
    "DooGroup-2023-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Academy 理财教育品牌"])},
    "DooGroup-2023-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Health 大健康品牌"])},
    "DooGroup-2023-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成为曼联足球俱乐部官方全球合作伙伴"])},
    "DooGroup-2023-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式成为西澳大利亚州澳中友好协会（ACFSWA）荣誉赞助商"])},
    "DooGroup-2023-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级为 AUFC 全球金牌合作伙伴"])},
    "AdelaideUnited-2023-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿德莱德联队庆祝成立 20 周年 "])},
    "AdelaideUnited-2023-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级为 Doo Group 全球金牌合作伙伴"])},
    "DooGroup-2022-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布 Doo Wealth 财富管理品牌"])},
    "DooGroup-2022-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全球客户达 60,000+"])},
    "DooGroup-2022-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为阿德莱德联足球俱乐部官方合作伙伴"])},
    "AdelaideUnited-2022-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为 Doo Group 官方合作伙伴"])},
    "AdelaideUnited-2016-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 A 联赛总决赛中战胜昆士兰狮吼队获得冠军"])},
    "DooGroup-2014-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Doo Group  成立，以金融科技集团模式运营"])},
    "DooGroup-2014-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在中国香港设立首个运营中心"])},
    "AdelaideUnited-2013-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马科斯·弗洛雷斯（Marcos Flores）首次获得约翰尼·沃伦奖章（Johnny Warren Medal），并被评为联盟最佳球员。"])},
    "AdelaideUnited-2013-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前锋塞尔吉奥·范戴克（Sergio van Dijk）在常规赛中打进 16 球，成为最佳射手，并获得了澳大利亚足球超级联赛金靴奖。"])},
    "AdelaideUnited-2008-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\n阿德莱德联队在亚冠决赛巅峰对决中，1-0 力克日本劲旅鹿岛鹿角，铸就历史辉煌，荣耀加冕，首开澳足问鼎亚洲之巅新纪元。"])},
    "AdelaideUnited-2003-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成立于 2003 年 9 月"])},
    "AdelaideUnited-2003-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继西阿德莱德队和阿德莱德城队退出后，阿德莱德联队加入了 2003/04 赛季的全国足球联赛（NSL）。"])},
    "goals-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 与阿德莱德联队正携手共绘璀璨未来，并肩奋斗，共创辉煌成功的篇章。"])},
    "ambition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雄心壮志"])},
    "ambition-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["凭借对热情和卓越的坚定承诺，Doo Group 和 AUFC 不断突破界限，达到新的高度。"])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忠诚"])},
    "loyalty-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 通过提供专业的服务和持续的价值增值来体现其忠诚。同时，阿德莱德联队 (AUFC) 作为南澳大利亚人的守护者，以足球为媒介，点燃激情的火焰，激发深藏的自豪感，引领着每一位支持者共筑远大目标。"])},
    "transparency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透明度"])},
    "transparency-desc-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 始终坚持透明原则，以保障客户权益。正如 AUFC 在所有业务中始终践行“以诚为基，沟通无界，信息透明，责任在肩”的承诺。"])},
    "coin-toss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 和 AUFC 在全球金牌合作盛典抛硬币开球环节"])},
    "cooper-stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["库珀斯体育场（Coopers Stadium）\n澳大利亚足球联合会引以为豪的主场"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在库珀体育场的每一场主场盛宴中，均可看到 Doo Group 的 LED 显示屏"])},
    "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUFC 与当地社区之间紧密联系"])},
    "dg-logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在澳大利亚足球超级联赛主客场的球员短裤上印有 Doo Group 的标志"])},
    "community-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的十大主营业务涵盖广泛的金融服务，为客户提供全面的金融服务和创新解决方案，以探索无限可能。"])},
    "doo-group-aus-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 悉尼总部"])},
    "doo-group-aus-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite 1302, Level 13, Tower 1, 475 Victoria Avenue, Chatswood NSW 2067."])},
    "doo-group-aus-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式：   02 8801 3188"])},
    "doo-group-chatswood-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 查兹伍德办事处"])},
    "doo-group-chatswood-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop 3, Ground Floor, 408-414 Victoria Avenue, Chatswood NSW 2067"])},
    "doo-group-chatswood-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式：   02 8801 3186"])},
    "doo-group-chinatown-head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 唐人街办事处"])},
    "doo-group-chinatown-head-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop D1, Sussex Centre, 56 Dixon Street, Haymarket NSW 2000"])},
    "doo-group-chinatown-head-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式：02 8801 3183"])},
    "partnership-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请继续关注我们与阿德莱德联队合作的最新进展。"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发现 &#064;Doo Group"])},
    "discover-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保持联系！关注我们的社交媒体以获取最新活动信息、见解。"])},
    "seo": {
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Group 与阿德莱德联队携手迈向更高的目标，共创美好未来"])},
      "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阿德莱德联队, Doo Group"])}
    }
  }
}